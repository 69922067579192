



























































































import { Component, Vue } from "vue-property-decorator";
import { readConstants } from '@/store/main/getters';
import { dispatchGetConstants } from '@/store/main/actions';


// import Vue from 'vue';
import Vuetify from "vuetify";

Vue.use(Vuetify);

@Component
export default class Plan extends Vue {
  async mounted () {
    await dispatchGetConstants(this.$store);
  }

    // update version
  checkVersion () {
    const constants = readConstants(this.$store);

    let onlineVersion = "none";
    if (constants) {
      onlineVersion = constants.version;
    }

    let localVersion = localStorage.getItem('vers');
    let updateSignal = localStorage.getItem('upt');

    // check if we need to fresh the browser
    if (onlineVersion != "none" && localVersion != onlineVersion) { // detect newer online version
      localStorage.setItem('vers', onlineVersion);
      window.location.reload();
    } else if (updateSignal == 'refresh') { // detect refresh signal
      localStorage.setItem('upt', 'no');
      window.location.reload();
    }
  }

  // adaptive screen size
  public isMobile = false;

  beforeDestroy () {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize)
    }
  }

  created () {
    window.addEventListener('resize', this.onResize);
    this.onResize();
    this.checkVersion();
  }

  onResize () {
    this.isMobile = window.innerWidth < 960;
  }
}
